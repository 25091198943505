#addNewStoryBtn {
    margin: 10px auto 0 10px;
}

.planPageTitle {
    text-align: center;
    margin: 20px auto 20px auto;
}

.planBasicInfoContainer {
    margin: 10px 10px 0 10px;
}

.planPageSubtitles {
    width: fit-content;
    font-size: 22px;
    font-weight: bold;
    /* border-bottom: 1px grey solid; */
    /* margin: 10px 0 10px 0; */
}

.planBasicText {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 20px;
}

.planEditBtn {
    width: 20px;
    float: right;
    margin-bottom: 10px;
}

.planEditBtn:hover {
    filter: drop-shadow(0 0 0 black);
}

.planDeleteBtnContainer {
    width: 100%;
    margin: 20px 0 20px 0;
    text-align: center;
}

.planDeleteBtn:hover {
    opacity: 1;
}

.planDeleteBtn {
    width: 10%;
    opacity: 0.2;
}