.myPlansPageTitle {
    position: relative;
    padding-left: 5%;
    height: 200px;
    background-color: #fff;
    font-size: 3rem;
    font-weight: 500;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #b3b1b1;
}

.plansPageAddPlanBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #212529;
}

.plansListContainer {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 5%;
}

.planThumbnailContainer {
    width: 100%;
    height: 100%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plansCol:hover .planThumbnailContainer {
    color: white;
}

.plansCol {
    border-radius: 10%;
    width: 100px;
    height: 100px;
    float: left;
    margin: 2vw;
    border-bottom: solid 1px rgb(180, 180, 180);
    border-right: solid 1px rgb(221, 221, 221);
    background: white;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.plansCol:hover {
    border-top: solid 1px rgb(180, 180, 180);
    border-left: solid 1px rgb(221, 221, 221);
    border-bottom: none;
    border-right: none;
    background: rgb(88, 88, 88);
    cursor: pointer;
    transform: translateY(-5px);
}

.MyPlansMain {
    background: #f3f3f3;
    height: calc(100vh - 56px);
}