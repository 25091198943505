.storyCheckboxContainer {
    text-align: right;
}

.customCheckbox {
    width: 20px;
}

.storyCostDisplay {
    float: left;
    border-bottom: solid grey 1px;
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.storyCheckboxContainer {
    float: right;
    margin: auto;
}

.storyCheckboxContainerLabel {
    font-size: 15px;
    padding-right: 10px;
}

.storyContentArea {
    resize: none;
    border: none;
    font-size: 18px;
}

/* .storyContentArea:hover {
    background: rgb(250, 250, 250);
    border: 1px grey groove;
} */

/* .storyContentArea:focus {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
    outline: none !important;
} */

.storyContentDisplay {
    /* flex-basis: 80%; */
    width: 70%;
    float: left;
}

.storyPriorityDisplay {
    float: right;
    margin: 0 0 auto 0;
}

@media only screen and (max-width: 575px) {
    .userStory {
        margin: 5px 10px 5px 10px;
        width: calc(100vw - 20px);
    }
}

@media only screen and (min-width: 576px) and (max-width: 1024px) {
    .userStory {
        margin: 5px 10px 5px 10px;
        width: calc((100vw - 40px)/2);
    }
}

@media only screen and (min-width: 1025px) {
    .userStory {
        margin: 5px 10px 5px 10px;
        width: calc((100vw - 60px)/3);
    }
}

.storyMainContainer {
    width: 100%;
}

.storyCoreContainer {
    width: 100%;
}

.storySectionMid {
    /* display: flex; */
    height: 80px;
    width: 100%;
}

.storySectionBot {}

.storyTitleDisplay {
    flex: 1;
}

.storyTitleDisplay {
    float: left;
    width: calc(100% - 20px);
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.closeDropdown {
    float: right;
}
.storyEditBtn {
    width: 20px;
    float: right;
    margin-top: 5px;
    padding-left: 1px;
}

.storyEditBtn:hover {
    filter: drop-shadow(0 0 0 black);
}

.storyCheckboxContainer:hover {
    filter: drop-shadow(0 0 0 black);
}

.storyDeleteBtn {
    width: 20px;
    margin-left: 0;
}

.storyDeleteBtn:hover {
    filter: drop-shadow(0 0 0 black);
}