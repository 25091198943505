.topNavContainer {
    color: white;
    height: 56px;
}

.topNavItem {
    text-decoration: none !important;
    color: hsla(0, 0%, 100%, .5) !important;
    margin: auto .5rem;
    transition: .2s;
}

.topNavItem:hover {
    color: hsla(0, 0%, 100%, .8) !important;
}